const KLARO_DOMAIN = window.location.hostname.match(/\./g).length > 1 ? window.location.hostname.substring(window.location.hostname.indexOf('.') + 1) : window.location.hostname;
export const KLARO_CONFIG = {
  cookieName: 'ccm.ensun.de',
  noAutoLoad: false,
  htmlTexts: true,
  embedded: false,
  groupByPurpose: true,
  cookieExpiresAfterDays: 120,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: false,
  noticeAsModal: false,
  disablePoweredBy: true,

  translations: {
    de: {
      privacyPolicyUrl: "/datenschutz",
      close: "Schließen",
      decline: "Notwendige erlauben",
      ok: "Alle akzeptieren",
      save: "Speichern",

      consentModal: {
        title: "Einstellungen zum Datenschutz",
        description: "Auf dieser Website werden Daten wie z.B. Cookies gespeichert, um wichtige Funktionen der Website, einschließlich Analysen, Marketingfunktionen und Personalisierung zu ermöglichen. Die Einstellungen können jederzeit angepasst oder alle Cookies akzeptiert werden."
      },

      privacyPolicy: {
        name: 'Datenschutzerklärung',
        text: 'Weitere Details in unserer {privacyPolicy}'
      },

      consentNotice: {
        changeDescription: 'Es gab Änderungen seit Deinem letzten Besuch, bitte aktualisiere Deine Auswahl.',
        description: '<strong>Auf dieser Website werden Daten wie z.B. Cookies gespeichert, um wichtige Funktionen der Website, einschließlich Analysen, Marketingfunktionen und Personalisierung zu ermöglichen.</strong> Die Einstellungen können jederzeit angepasst oder alle Cookies akzeptiert werden.',
        learnMore: 'Anpassen'
      },

      acceptSelected: 'Auswahl speichern',
      acceptAll: 'Alle akzeptieren',

      service: {
        disableAll: {
          title: "Alle Dienste aktivieren oder deaktivieren",
          description: 'Mit diesem Schalter alle Anwendungen aktivieren/deaktivieren.'
        },
        required: {
          title: " (erforderlich)",
          description: "required description"
        },
        purpose: "Zweck",
        purposes: "Zwecke"
      },

      contextualConsent: {
        acceptAlways: "Immer laden",
        acceptOnce: "Akzeptieren und Inhalte laden",
        description: "Von {title} bereitgestellte externe Inhalte laden?"
      },

      purposeItem: {
        service: "Dienst",
        services: "Dienste"
      },

      purposes: {
        required: {
          title: 'Technisch notwendig',
          description: 'Diese Cookies sind für eine gute Funktionalität unserer Website erforderlich und können in unserem System nicht ausgeschaltet werden.'
        },
        marketing: {
          title: 'Marketing',
          description: 'Durch Marketing Cookies können wir die individuelle Nutzererfahrung unserer Website stetig verbessern'
        },
        functional: {
          title: 'Funktional',
          description: 'Wir verwenden diese Cookies, um die Funktionalität zu verbessern und die Personalisierung zu ermöglichen, z.B. ein Instagram Feed.'
        },
        analytics: {
          title: 'Analyse',
          description: 'Wir verwenden diese Cookies, um statistische Informationen über unsere Website und Besucher anonymisiert bereitzustellen. Sie werden zur Leistungsmessung und -verbesserung verwendet.'
        }
      },

      cookieManager: {
        description: 'Für die Einstellung dieses Cookie-Managers wird ein Cookie gesetzt. Dieser dient ausschließlich der Speicherung der Einstellungen wie sie getroffen wurden und ist demnach technisch erforderlich.'
      },
      googleAnalytics: {
        description: 'Um die Seite fortlaufend zu verbessern, analysieren wir das Verhalten der Besucher mit Google Analytics'
      },
      facebookPixel: {
        description: 'Wir verwenden den Facebook Pixel für Website-Analysen, Ad-Targeting und Anzeigenmessung.'
      }
    },
    en: {
      privacyPolicyUrl: "/privacy",
      close: "Close",
      decline: "Accept necessary",
      ok: "Accept all",
      save: "Save",

      consentModal: {
        title: "Settings for Privacy",
        description: "This website saves data e.g. cookies to enable important website functionality including analytics, marketing and personalization. You can always change the settings or accept all cookies."
      },

      privacyPolicy: {
        name: 'Privacy Policy',
        text: 'More details in our {privacyPolicy}'
      },

      consentNotice: {
        changeDescription: 'There were changes since you last visit, please update you choice.',
        description: "This website saves data e.g. cookies to enable important website functionality including analytics, marketing and personalization. You can always change the settings or accept all cookies.",
        learnMore: 'Update'
      },

      acceptSelected: 'Save selected',
      acceptAll: 'Accept all',

      service: {
        disableAll: {
          title: "Enable or disable all Services",
          description: 'Enable or disable all services with this button.'
        },
        required: {
          title: " (required)",
          description: "required description"
        },
        purpose: "Purpose",
        purposes: "Purposes"
      },

      contextualConsent: {
        acceptAlways: "Accept always",
        acceptOnce: "Accept once",
        description: "Accept proviced content of {title}?"
      },

      purposeItem: {
        service: "Service",
        services: "Services"
      },

      purposes: {
        required: {
          title: 'Technically required',
          description: 'These cookies are required for correct functionality of our website and can not be disabled.'
        },
        marketing: {
          title: 'Marketing',
          description: 'Due to marketing cookies we can help to improve the individual user experience'
        },
        functional: {
          title: 'Functionality',
          description: 'Due to functionality cookies we can improve the functionality and personalization of this page.'
        },
        analytics: {
          title: 'Analysis',
          description: 'This cookies will gather anonymized statistic information of this website and its users. We will use this information for performance measurement and improvement'
        }
      },

      cookieManager: {
        description: 'The settings of this cookie manager are also stored in its own cookie. This cookie only saves the settings that were made in this manager and is therefore technically required.'
      },
      googleAnalytics: {
        description: 'To keep this website in ongoing improvement we analyze the behaviour of our customers by Google Analytics.'
      },
      facebookPixel: {
        description: 'Facebook Pixel is used for analyzing the website, ad targeting and ad measurement'
      }
    }
  },

  // Drittanbieterdienste
  services: [
    {
      name: 'cookieManager',
      title: 'Cookie Manager',
      purposes: ['required'],
      required: true,
    },
    {
      name: 'googleAnalytics',
      title: 'Google Analytics',
      cookies: [
        ['_ga', '/', '.' + KLARO_DOMAIN],
        ['_gid', '/', '.' + KLARO_DOMAIN],
        [/^_ga_.*$/i, '/', '.' + KLARO_DOMAIN],
        [/^_gat_.*$/i, '/', '.' + KLARO_DOMAIN],
        [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN],
        [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN]
      ],
      purposes: ['analytics']
    }
  ]
};

