const DROPDOWN_ITEMS = document.querySelectorAll('[data-dropdown-item]');

export const INIT_NAVBAR_DROPDOWNS = () => {
  if (DROPDOWN_ITEMS.length <= 0) return;

  DROPDOWN_ITEMS.forEach(ITEM => {

    const DROPDOWN = ITEM.querySelector('[data-dropdown]'),
      DROPDOWN_CONTAINER = ITEM.querySelector('[data-dropdown-container]')

    ITEM.querySelector('[data-dropdown-trigger]')
      .addEventListener('click', () => {

        DROPDOWN_ITEMS.forEach(INNER_ITEM => {
          if (INNER_ITEM !== ITEM) {
            const INNER_DROPDOWN = INNER_ITEM.querySelector('[data-dropdown]');
            if (INNER_DROPDOWN) INNER_DROPDOWN.setAttribute('style', `opacity: 0;`);
            INNER_ITEM.removeAttribute('data-active');
          }
        })

        const DROPDOWN_HEIGHT = DROPDOWN_CONTAINER ? DROPDOWN_CONTAINER.getBoundingClientRect().height + 8 : 0;

        if (ITEM.hasAttribute('data-active')) {
          ITEM.removeAttribute('data-active');
          DROPDOWN.setAttribute('style', `opacity: 0;`);
        } else {
          if (window.matchMedia('(max-width: 1199.9px)').matches)
            DROPDOWN.setAttribute('style', `opacity: 1; height: ${DROPDOWN_HEIGHT}px;`);
          ITEM.setAttribute('data-active', '');
        }
      });

    const MOUSE_ENTER = () => {
      if (window.matchMedia('(min-width: 1200px)').matches) {

        const DROPDOWN_HEIGHT = DROPDOWN_CONTAINER ? DROPDOWN_CONTAINER.getBoundingClientRect().height + 8 : 0;

        if (ITEM.hasAttribute('data-active')) {
          ITEM.removeAttribute('data-active');
          DROPDOWN.setAttribute('style', `opacity: 0;`);
        } else {
          if (window.matchMedia('(max-width: 1199.9px)').matches)
            DROPDOWN.setAttribute('style', `opacity: 1; height: ${DROPDOWN_HEIGHT}px;`);

          ITEM.setAttribute('data-active', '');
        }
      }
    };

    const MOUSE_LEAVE = () => {
      if (window.matchMedia('(min-width: 1200px)').matches) {
        const DROPDOWN = ITEM.querySelector('[data-dropdown]');
        if (DROPDOWN) DROPDOWN.setAttribute('style', `opacity: 0;`);
        ITEM.removeAttribute('data-active');
      }
    };

    ITEM.addEventListener('mouseenter', MOUSE_ENTER);
    ITEM.addEventListener('mouseleave', MOUSE_LEAVE);

  })

  document.addEventListener('click', e => {
    DROPDOWN_ITEMS.forEach(ITEM => {
      const DROPDOWN = ITEM.querySelector('[data-dropdown]');

      if (e.composedPath().indexOf(ITEM) === -1) {
        ITEM.removeAttribute('data-active');
        if (ITEM.querySelector('[data-dropdown]'))
          DROPDOWN.setAttribute('style', `opacity: 0;`);
      }
    })
  })
}

