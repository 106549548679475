const FOOTER_C2A = document.querySelector('[data-footer-c2a]');
const FOOTER_IMAGE = document.querySelector('[data-footer-c2a-image]');

function MOUSE_MOVE() {
  FOOTER_C2A.addEventListener('mousemove', e => {
    const {x, y, width, height} = FOOTER_C2A.getBoundingClientRect();
    const Y_FACTOR = 5;
    const X_FACTOR = 5;

    const X_POS = Math.ceil(((e.clientX - x) / width) * X_FACTOR);
    const Y_POS = Math.ceil(((e.clientY - y) / height) * Y_FACTOR);

    FOOTER_IMAGE.style.transform = `translate(${X_POS}%, ${Y_POS}%) scale(1.1)`;
  });
}
function MOUSE_LEAVE() {
  FOOTER_C2A.addEventListener('mouseleave', () => {
    FOOTER_IMAGE.style.transform = `translate(0%, 0%) scale(1)`;
  });
}

function INIT_MOUSE_EVENTS() {
  MOUSE_MOVE();
  MOUSE_LEAVE();
}

export const INIT_FOOTER_C2A = () => {
  if (!FOOTER_C2A) return;

  const OBSERVER_OPTIONS = {
    rootElement: document.querySelector('[data-footer-c2a-root]'),
    rootMargin: '16px 0px -25% 0px',
  }
  const FOOTER_C2A_OBSERVER = new IntersectionObserver(ENTRIES => {
    ENTRIES.forEach(ENTRY => {
      if (ENTRY.isIntersecting) {

        if (!ENTRY.target.hasAttribute('data-active')) {
          ENTRY.target.setAttribute('data-active', '')
        }
      }
    })
  }, OBSERVER_OPTIONS)

  FOOTER_C2A_OBSERVER.observe(FOOTER_C2A);

  INIT_MOUSE_EVENTS();
};
