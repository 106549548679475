import {
  REMOVE_OVERLAY,
  BODY,
  SEARCH_TOGGLES,
  SEARCH_OVERLAY,
  NAVBAR_OVERLAY,
  NAVBAR_TOGGLE,
  BG_OVERLAY,
  NAVBAR_ITEMS
} from "./_helpers.overlay";

export const INIT_NAVBAR_ITEMS = () => {
  NAVBAR_ITEMS.forEach((item) => item.addEventListener('click', () => {
    if(item.hasAttribute('data-dropdown-item')) return
    REMOVE_OVERLAY(BODY);
    REMOVE_OVERLAY(BG_OVERLAY);
    REMOVE_OVERLAY(NAVBAR_OVERLAY);
    REMOVE_OVERLAY(SEARCH_OVERLAY);
    REMOVE_OVERLAY(NAVBAR_TOGGLE);
    SEARCH_TOGGLES.forEach(toggle => REMOVE_OVERLAY(toggle));
  }));
};
