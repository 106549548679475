import {
  ADD_OVERLAY,
  REMOVE_OVERLAY,
  HAS_OVERLAY,
  BODY,
  SEARCH_TOGGLES,
  SEARCH_OVERLAY,
  NAVBAR_OVERLAY,
  NAVBAR_TOGGLE,
  BG_OVERLAY
} from "./_helpers.overlay";


export const INIT_SEARCH_TOGGLES = () => {
  SEARCH_TOGGLES.forEach(toggle => toggle.addEventListener('click', () => {

    if (!HAS_OVERLAY(BODY)) {
      ADD_OVERLAY(BODY);
      ADD_OVERLAY(BG_OVERLAY);
    }

    if (!HAS_OVERLAY(SEARCH_OVERLAY)) {
      ADD_OVERLAY(SEARCH_OVERLAY);
      SEARCH_TOGGLES.forEach(innerToggle => ADD_OVERLAY(innerToggle));

    } else if (HAS_OVERLAY(SEARCH_OVERLAY)) {
      REMOVE_OVERLAY(BODY);
      REMOVE_OVERLAY(BG_OVERLAY);
      REMOVE_OVERLAY(SEARCH_OVERLAY);
      SEARCH_TOGGLES.forEach(innerToggle => REMOVE_OVERLAY(innerToggle));
    }
    REMOVE_OVERLAY(NAVBAR_OVERLAY);
    REMOVE_OVERLAY(NAVBAR_TOGGLE);
  }));
};
