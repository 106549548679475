const SEARCH_SUGGESTS = document.querySelectorAll("[data-suggest]");
const HANDLE_SEARCH_SUGGEST = suggest => {
  const SUGGESTS_CONTAINER = suggest.closest("[data-suggests-container]");

  if (!SUGGESTS_CONTAINER) return;
  const SEARCH_FORM = SUGGESTS_CONTAINER.parentNode.querySelector("[data-search-form]");

  if (!SEARCH_FORM) return;
  const SEARCH_FORM_INPUT = SEARCH_FORM.querySelector("[data-search-input]");

  if (!SEARCH_FORM_INPUT) return;
  SEARCH_FORM_INPUT.value = suggest.innerText;
  SEARCH_FORM_INPUT.focus();
  SEARCH_FORM.submit();
}

export const INIT_SEARCH_SUGGESTS = () => {
  if (SEARCH_SUGGESTS.length <= 0) return
  SEARCH_SUGGESTS.forEach(suggest => suggest.addEventListener("click", () => HANDLE_SEARCH_SUGGEST(suggest)));
}
