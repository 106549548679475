import * as Klaro from 'klaro/dist/klaro';
import {KLARO_CONFIG} from './config.ccm'

import {INIT_EVENTS} from "./events.ccm"

const CCM_TRIGGER = document.querySelector("[data-ccm-trigger]");

const ADD_CARET_EVENTS = () => {
  setTimeout(() => {
    const CARETS = document.querySelectorAll('.cm-caret a')
    if (CARETS.length > 0) {
      CARETS.forEach(CARET => {
        CARET.addEventListener('click', () => {
          if (CARET.hasAttribute('data-active')) {
            CARET.removeAttribute('data-active')
          } else {
            CARET.setAttribute('data-active', '')
          }
        })
      })
    }
  }, 50)
}

const INIT_KLARO = () => {
  window.klaro = Klaro;
  window.klaroConfig = KLARO_CONFIG;
  Klaro.setup(KLARO_CONFIG);

  const KLARO_MODAL = document.querySelector('#klaro')
  if (KLARO_MODAL) {
    const LEARN_MORE_BUTTON = document.querySelector('.cm-link.cn-learn-more');
    LEARN_MORE_BUTTON
      ? LEARN_MORE_BUTTON.addEventListener('click', () => ADD_CARET_EVENTS())
      : false;
  }
}

export const INIT_COOKIE_MANAGER = () => {
  INIT_KLARO()
  INIT_EVENTS()

  CCM_TRIGGER.addEventListener("click", () => {
    Klaro.show(KLARO_CONFIG, true)
    const KLARO_MODAL = document.querySelector('#klaro')
    if (KLARO_MODAL) {
      ADD_CARET_EVENTS();
    }
  })
}

