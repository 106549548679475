
export const BODY = document.querySelector('body');
export const SEARCH_TOGGLES = document.querySelectorAll('[data-search-toggle]');
export const SEARCH_OVERLAY = document.querySelector('[data-search-overlay]');
export const NAVBAR_OVERLAY = document.querySelector('[data-navbar-overlay]');
export const NAVBAR_TOGGLE = document.querySelector('[data-navbar-toggle]');
export const NAVBAR_ITEMS = document.querySelectorAll('.nav-item, [data-nav-action]');
export const BG_OVERLAY = document.querySelector('[data-bg-overlay]');

export const HAS_OVERLAY = (elem) => elem.hasAttribute('data-overlay-visible');
export const ADD_OVERLAY = (elem) => elem.setAttribute('data-overlay-visible', '');
export const REMOVE_OVERLAY = (elem) => elem.removeAttribute('data-overlay-visible');
