const LANG_NAVS = document.querySelectorAll("[data-lang-nav]");
const TOGGLE_NAV = LANG_NAV => LANG_NAV.hasAttribute("data-active")
  ? LANG_NAV.removeAttribute("data-active")
  : LANG_NAV.setAttribute("data-active", "");

const SET_MAX_HEIGHT_TO_FLYOUT = (LANG_NAV) => {
  const FLYOUT = LANG_NAV.querySelector("[data-flyout]");
  if (!FLYOUT) return;

  const LANG_NAV_NAV = FLYOUT.querySelector("ul");
  if (!LANG_NAV_NAV) return;

  const FLYOUT_MAX_HEIGHT = LANG_NAV_NAV.getBoundingClientRect().height;

  LANG_NAV.hasAttribute("data-active")
    ? FLYOUT.setAttribute("style", `max-height: ${FLYOUT_MAX_HEIGHT}px;`)
    : FLYOUT.removeAttribute("style");
};

export const INIT_NAVBAR_LANG = () => {
  if (LANG_NAVS.length <= 0) return;
  LANG_NAVS.forEach(LANG_NAV => LANG_NAV.addEventListener("click", () => {
    TOGGLE_NAV(LANG_NAV);
    window.matchMedia("(max-width: 991.9px)").matches ? SET_MAX_HEIGHT_TO_FLYOUT(LANG_NAV) : false;
  }));

  document.addEventListener("click", e => LANG_NAVS.forEach(LANG_NAV => {
    const COMPOSED_PATH = e.composedPath().indexOf(LANG_NAV) === -1;
    COMPOSED_PATH
      ? LANG_NAV.removeAttribute("data-active") :
      LANG_NAV.querySelector("[data-flyout]").removeAttribute("style");
  }));
}
