const header = document.querySelector('[data-sub-hero-content]');

function fadeOutOnScroll(element) {
    if (!element) return;

    let distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
    const elementHeight = element.offsetHeight;
    const scrollTop = document.documentElement.scrollTop;

    let opacity = 1;
    let multiplicator = 1;

    if (window.matchMedia("(min-width: 576px)").matches) multiplicator = 1.5;

    if (scrollTop > distanceToTop) {
        opacity = 1 - ((scrollTop * multiplicator) - distanceToTop) / elementHeight;
    }

    if (opacity >= 0) element.style.opacity = opacity;
}

function scrollHandler() {
    fadeOutOnScroll(header);
}

export const INIT_FADE = () => window.addEventListener('scroll', scrollHandler);
