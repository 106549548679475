import {
  ADD_OVERLAY,
  REMOVE_OVERLAY,
  HAS_OVERLAY,
  BODY,
  SEARCH_TOGGLES,
  SEARCH_OVERLAY,
  NAVBAR_OVERLAY,
  NAVBAR_TOGGLE,
  BG_OVERLAY
} from "./_helpers.overlay";

export const INIT_NAVBAR_TOGGLE = () => {
  NAVBAR_TOGGLE.addEventListener('click', () => {
    if (!HAS_OVERLAY(BODY)) {
      ADD_OVERLAY(BODY);
      ADD_OVERLAY(BG_OVERLAY);
    }

    if (!HAS_OVERLAY(NAVBAR_OVERLAY)) {
      ADD_OVERLAY(NAVBAR_OVERLAY);
      ADD_OVERLAY(NAVBAR_TOGGLE);

    } else if (HAS_OVERLAY(NAVBAR_OVERLAY)) {
      REMOVE_OVERLAY(NAVBAR_OVERLAY);
      REMOVE_OVERLAY(NAVBAR_TOGGLE);
      REMOVE_OVERLAY(BODY);
      REMOVE_OVERLAY(BG_OVERLAY);
    }

    REMOVE_OVERLAY(SEARCH_OVERLAY);
    SEARCH_TOGGLES.forEach(toggle => REMOVE_OVERLAY(toggle));
  });
};
