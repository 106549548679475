import {INIT_COOKIE_MANAGER} from "./js/ccm/init.ccm";

import {INIT_OVERLAY_TOGGLES} from "./js/components/init-overlay-toggles";
import {INIT_NAVBAR} from "./js/components/init-navbar";
import {INIT_SEARCH} from "./js/components/init-search";
import {INIT_SUB_HERO} from "./js/components/init-sub-hero";
import {INIT_TOOL_TIPS} from "./js/components/init-tooltips";
import {INIT_FOOTER_C2A} from "./js/components/init-footer-c2a";


document.documentElement.classList.replace("no-js", "js-rules");
INIT_COOKIE_MANAGER();
INIT_OVERLAY_TOGGLES();
INIT_NAVBAR();
INIT_SEARCH();
INIT_SUB_HERO();
INIT_TOOL_TIPS();
INIT_FOOTER_C2A();










