const NAV_BAR = document.querySelector("header nav.navbar")

const SET_NAVBAR_BG_DEPENDING_ON_SCROLL = () => {
  window.window.scrollY > 50
    ? NAV_BAR.setAttribute("data-navbar-bg", "true")
    : NAV_BAR.setAttribute("data-navbar-bg", "false");
};

export const INIT_NAVBAR_SCROLL = () => {
  if (!NAV_BAR) return;
  SET_NAVBAR_BG_DEPENDING_ON_SCROLL();
  window.addEventListener("scroll", () => SET_NAVBAR_BG_DEPENDING_ON_SCROLL());
}
