import RELLAX from 'rellax/rellax.min.js';
const parallaxElements = document.querySelectorAll('[data-sub-hero-image], [data-sub-hero-content]');


export const INIT_PARALLAX = () => {
    if(!parallaxElements) return
    parallaxElements.forEach(item => {
        const parallax = new RELLAX(item);
    });

}
