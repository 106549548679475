const HERO_SEARCH_FORM = document.querySelector(".hero [data-search-form]");
const SEARCH_TOGGLES = document.querySelectorAll("[data-search-toggle]");

const SEARCH_OBSERVER = new window.IntersectionObserver(([entry]) => entry.isIntersecting
  ? SEARCH_TOGGLES.forEach(toggle => toggle.dataset.searchToggle = "hidden")
  : SEARCH_TOGGLES.forEach(toggle => toggle.dataset.searchToggle = "visible"));

export const INIT_SEARCH_OBSERVER = () => {
  if (HERO_SEARCH_FORM && window.IntersectionObserver) {
    SEARCH_OBSERVER.observe(HERO_SEARCH_FORM);
  } else {
    if (SEARCH_TOGGLES.length > 0) {
      SEARCH_TOGGLES.forEach(toggle => toggle.dataset.searchToggle = "visible");
    }
  }
}
