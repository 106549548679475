const TRACKING_EVENTS = document.querySelectorAll("[data-tracking-event]")

const RETURN_EVENT_POSITION = item => item.dataset.trackingPosition && item.dataset.trackingPosition !== ""
    ? item.dataset.trackingPosition
    : "undefined",

  RETURN_EVENT_LABEL = item => item.dataset.trackingLabel && item.dataset.trackingLabel !== ""
    ? item.dataset.trackingLabel
    : "undefined";

export const INIT_EVENTS = () => {
  TRACKING_EVENTS.forEach(TRACKING_EVENT => TRACKING_EVENT.addEventListener("click", () => {
    gtag("event", TRACKING_EVENT.dataset.trackingEvent, {
      button_position: RETURN_EVENT_POSITION(TRACKING_EVENT),
      button_label: RETURN_EVENT_LABEL(TRACKING_EVENT)
    });
  }));
};
