import {
  REMOVE_OVERLAY,
  HAS_OVERLAY,
  BODY,
  SEARCH_TOGGLES,
  SEARCH_OVERLAY,
  NAVBAR_OVERLAY,
  NAVBAR_TOGGLE,
  BG_OVERLAY
} from "./_helpers.overlay";


export const INIT_BG_OVERLAY = () => {
  BG_OVERLAY.addEventListener('click', e => {
    let missedNavToggle = e.target !== NAVBAR_TOGGLE;
    let missedSearchToggle = true;
    let missedSearch = e.composedPath().indexOf(document.querySelector('[data-search-overlay]')) === -1;
    let missedNav = e.composedPath().indexOf(document.querySelector('[data-navbar-overlay]')) === -1;
    SEARCH_TOGGLES.forEach(toggle => {
      if (e.target === toggle) {
        missedSearchToggle = false;
      }
    });

    if (HAS_OVERLAY(BODY)) {
      if (HAS_OVERLAY(NAVBAR_OVERLAY) && missedNav && missedNavToggle) {
        REMOVE_OVERLAY(BODY);
        REMOVE_OVERLAY(BG_OVERLAY);
        REMOVE_OVERLAY(NAVBAR_OVERLAY);
        REMOVE_OVERLAY(NAVBAR_TOGGLE);
      } else if (HAS_OVERLAY(SEARCH_OVERLAY) && missedSearch && missedSearchToggle) {
        REMOVE_OVERLAY(BODY);
        REMOVE_OVERLAY(BG_OVERLAY);
        REMOVE_OVERLAY(SEARCH_OVERLAY);
        SEARCH_TOGGLES.forEach(toggle => REMOVE_OVERLAY(toggle));
      }
    }
  });
};
